/*.pos-rel-overflow-hide {
  position: relative;
  overflow: hidden;
  // min-height: 50vh;
  min-height: 340px;
  // height: calc(100vh - 170px);
}*/
table {
  .timing-header {
    position: relative;
    background: #fff;
    background-color: #fff;
    /*z-index: 9;*/
    border-top: 1px solid #dee2e6;
  }
}

.table-reservation {
  tr {
    td {

      .custom-control-label::before,
      .custom-control-label::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.credit-container {
  position: absolute;
  width: 1020px;
  max-width: 100%;
  height: 300px;
  top: -300px;
  right: 5px;
  z-index: 999;
  background: #f2f2f2;
  padding: 15px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;

  &.open {
    top: 0;
  }

  .btn-credit {
    background: #1dc7ea;
    color: #fff;
    border: none;
    width: 100px;
    transform: rotate(0deg);
    position: absolute;
    bottom: -30px;
    right: 10px;
    height: 30px;
    border-radius: 0 0 6px 6px;
  }

  .credit-ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
          font-size: 14px;

          small {
            display: block;
          }
        }
      }
    }
  }

  .tab-content {
    max-height: 240px;
    font-size: 14px;

    .table-responsive {
      max-height: 220px;
      overflow: auto;

      table {
        margin-bottom: 0;
      }
    }
  }

  .card-header-tabs {
    margin: 0;

    .nav-link {
      border: none;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .avail-credit-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 15px 0;
  }
}

/*Tickit Section*/
.chatbox {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.chat-msg-scroll {
  height: 250px;
  padding-right: 20px;
  position: relative;
}

.chat-msg {
  color: #444;
  padding: 8px 18px;
  line-height: 20px;
  font-size: 13px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
  background-color: #f2f6f8;
  border: 1px solid #dbe3e7;
  border-radius: 10px;
  max-width: 100%;
  width: 400px;
  text-align: left !important;
}

.upfate-form {
  .form-check {
    .form-check-label {
      padding-left: 5px;
      font-size: 13px;
    }
  }
}

.text-divider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-divider>span:first-child {
  width: 100%;
  height: 1px;
  flex: 1;
  /*background: #ebecf1;*/
  display: inline-block;
}

.text-divider>span:not(:first-child):not(:last-child) {
  padding: 0 20px;
  /*width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefefee;
  border-radius: 50%;
   border: 1px solid #e5e5e5; */
}

.text-divider>span:last-child {
  width: 100%;
  height: 1px;
  flex: 1;
  /*background: #ebecf1;*/
  display: inline-block;
}

/*payment card*/
.card.payment-card {
  width: 500px;
  max-width: 100%;

  label {
    font-weight: 600;
    color: #303b93;
    flex: none;
  }

  .form-control {
    border: none;
    border-bottom: 1px solid #e1dede;
    border-radius: 0;
    padding: 0 40px 0 0;
  }
}

img.pciLogo {
  width: 80px;
}

img.sslLogo {
  width: 40px;
}

.payment-formicon {
  font-size: 23px;
  color: #5962a9;
  position: absolute;
  right: 12px;
  opacity: 0.5;
}

.card.success-box {
  background: #f0feea;
}

/*Utility style*/
.w-200px {
  width: 200px;
}

.fs-60 {
  font-size: 60px;
}

.fs-30 {
  font-size: 30px;
}

.fs-22 {
  font-size: 22px;
}

.fs-1rem {
  font-size: 1rem;
}

.text-green {
  color: #4bb71b;
}

.fw-600 {
  font-weight: 500;
}

.fs-20px {
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.blue-bg {
  background-color: #303b93 !important;

  .card-title {
    color: #fff !important;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}

/*Success Message Style*/
.success-animation {
  margin: 10px auto 30px;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #f0feea;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.chat-section {
  padding: 15px 0 15px 15px;
  background: #eeeff7;
  border-radius: 4px;
  border: 1px solid #e6e6ed;
}

.chattypearea {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  resize: none;
  padding-right: 40px;
  height: 40px;
  width: calc(100% - 70px);
}

.c-pointer {
  cursor: pointer;
}

.send-msg {
  position: absolute;
  right: 0;
  top: 0;
}

.chatbox .ps__thumb-y {
  background: #d6d8e9;
}

.play-btn-none .pp-btn {
  display: none;
}

/*Tickit Page*/
.btn-icon {
  font-size: 1rem !important;
  color: #303b93 !important;
  cursor: pointer;
  padding: 6px 12px;
  width: auto !important;
  line-height: 24px;
  min-width: 40px;
}

#svgDataShow img {
  width: 100%;
  //height: 860px;
  height: 800px;
}

.text-note {
  border-left: 2px solid #eee;
  padding: 0 20px;
  /*  background: #f5f5f5;*/
}

.text-note p {
  font-size: 13px;
  color: #555;
}

/*Full page condition*/

.full-page .main-panel.expanded {
  width: 100%;
}

.full-page .navbar {
  display: none;
}

.full-page .container-fluid {
  margin-top: 0;
}

/*Ticket Details Modal*/
.tickit-details-table {
  tr {
    td {
      word-break: break-word;
      line-height: 16px;
      padding: 2px 8px !important;
      font-size: 13px;
    }

    th {
      white-space: nowrap;
      padding: 2px 8px !important;
      font-size: 13px;
    }
  }
}

.citation-details-table {
  table-layout: fixed;
}
.citation-details-table tr th {
 width: 150px;
 min-width: 150px;
}

.display-error h1 {
  font-size: 22px;
  position: relative;
  top: 150px;
}

.mt-3.mt-sm-0.row:not(:first-child) .col-sm-auto label {
  min-height: 0 !important;
  margin-bottom: 0;
  font-size: 0;
}

.help-block {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}
.card .table-bordered td {
  word-break: break-all
}



@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

@media only screen and (max-width: 767px) {
  .chat-msg-scroll {
    height: calc(100vh - 469px);
  }

  .credit-container {
    max-width: 100%;

    .row {
      height: 260px;
      overflow: auto;
    }

    .card-header-tabs {
      .nav-link {
        font-size: 12px;
        font-weight: 600;
        padding: 0.5rem 0.5rem;
      }
    }

    .table {

      td,
      th {
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .chat-msg {
    width: 280px;
  }
}

.datePickerPayment {
  position: relative;
  z-index: 1;
  background: transparent !important;
  cursor: pointer;
}

/* css for dispute page */

.citation-table {
  border-top: 1px solid #dee2e6 !important;

  tr {
    td {
      white-space: nowrap;
    }

    th {
      white-space: nowrap;
    }
  }
}