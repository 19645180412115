.rdt_TableHeader {
  display: none !important;
  & + header {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ddd;
  }
}
.active-permit-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .search-container {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      left: 12px;
      color: #9a9a9a;
    }
    input {
      padding-left: 40px;
    }
  }
}
.m-w-200 {
  min-width: 200px;
}
/*---------- Custom Checkbox ----------*/
.fancy-checkbox input[type="checkbox"] + span {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #000;
  font-weight: 500;
}
.fancy-checkbox input[type="checkbox"] {
  display: none;
}
.fancy-checkbox input[type="checkbox"] + span i {
  display: inline-block;
  vertical-align: middle;
  align-self: normal;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  bottom: 1px;
  content: "";
  border: 1px solid #c1c1c1;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background: #fff;
}
.fancy-checkbox input[type="checkbox"]:checked + span i {
  background: #24a5ca;
  border: 1px solid #24a5ca;
}
.fancy-checkbox input[type="checkbox"]:checked + span i:after {
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  position: relative;
  top: 2px;
  left: 5px;
  content: "";
  background-color: transparent;
}
.fancy-checkbox input[type="checkbox"]:indeterminate + span i {
  background: #24a5ca;
  border: 1px solid #24a5ca;
}
.fancy-checkbox input[type="checkbox"]:indeterminate + span i:after {
  width: 8px;
  border-bottom: 2px solid #fff;
  display: block;
  position: relative;
  top: 6px;
  left: 3px;
  content: "";
  background-color: transparent;
}
.fancy-checkbox.sm input[type="checkbox"] + span i {
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
}
.fancy-checkbox.sm input[type="checkbox"]:checked + span i:after {
  width: 4px;
  height: 8px;
  top: 1px;
  left: 4px;
}

/*---------- Custom Radio ----------*/
.fancy-radio input[type="radio"] + span {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.fancy-radio input[type="radio"] {
  display: none;
}
.fancy-radio input[type="radio"] + span i {
  display: inline-block;
  position: relative;
  bottom: 1px;
  content: "";
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid #c2c2c2;
  background: #fff;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.fancy-radio input[type="radio"]:checked + span i {
  background: rgb(246, 142, 30);
  border: 1px solid rgb(246, 142, 30);
  box-shadow: 0px 0px 15px 0px rgba(246, 142, 30, 0.51);
}
.fancy-radio.sm input[type="radio"] + span i {
  width: 14px;
  height: 14px;
}
