
.contact-container{
    .contact-link{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin: 5px 0;
        i{
            font-size: 20px;
            font-weight: 700;
            margin-right: 10px;
        }
    }
}