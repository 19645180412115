/*     General overwrite     */
body,
.wrapper {
  min-height: 100vh;
  position: relative;
}
a {
  color: $info-color;

  &:hover,
  &:focus {
    color: $info-states-color;
    text-decoration: none;
  }
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  width: 18px;
  text-align: center;
}
.margin-top {
  margin-top: 50px;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

// documentation

.page-header {
  .page-header-image {
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .title-container {
    color: #fff;
    position: relative;
    top: 250px;
    z-index: 3;
  }
  .filter:after {
    background: rgba(0, 0, 0, 0)
      linear-gradient(to bottom, #9368e9 0%, #943bea 100%) repeat scroll 0 0 /
      150% 150%;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.77;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.documentation .page-header,
.documentation .page-header-image,
.documentation .page-header-image .filter:after {
  height: 100vh;
}

.documentation .footer {
  z-index: 3;
}
.documentation .wrapper {
  margin-top: -61px;
  height: 100vh;
}
.documentation .navbar {
  z-index: 21;
}
.credit-btn.btn-primary,
.credit-btn.btn-primary:active,
.credit-btn.btn-primary:focus,
.credit-btn.btn-primary:hover {
  position: absolute;
  right: 50px;
  top: 0;
  background: #1dc7ea;
  border-color: #1dc7ea;
  color: #fff;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.credit-collapse {
  width: 90%;
  min-width: 300px;
  top: 40px;
  right: 50px;
  position: absolute;
  z-index: 2;
  background: #f2f2f2;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  .card {
    background: transparent;
  }
  .card-header-tabs {
    margin: 0;
  }
}
._1ceqH {
  ._2fG9h {
    padding: 0;
  }
  ._dt3-T {
    display: inline-flex;
  }
  ._3bC73 {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    ._1YOWG {
      position: static;
      left: 0;
      transform: translate(0);
      bottom: 0px;
    }
    ._1dpop {
      margin-right: 10px;
      position: static;
      left: 0;
      transform: translate(0);
      bottom: 0px;
      width: auto;
      height: auto;
      display: inline-flex;
      background: transparent;
    }
    ._3wi1g {
      fill: #000;
    }
    ._qxztz {
      position: static;
      left: 0;
      transform: translate(0);
      bottom: 0px;
      padding: 0;
    }
    ._f2DT8 {
      float: none !important;
      text-align: left;
    }
    audio {
      display: none;
    }
  }
}
