html {
  height: 100%;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .login-container {
    height: 100%;
    flex: 1 1;
    background-position: center;
    background-size: cover;
    .login-card {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      width: 760px;
      max-width: 94%;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      .info-container {
        flex: 0 0 48%;
        padding: 30px 20px;
        background: #3393c6;
        color: #fff;
        .title {
          font-size: 18px;
          text-align: center;
          margin: 0 0 15px 0;
          font-weight: 500;
          line-height: 1.4;
        }
        p {
          font-size: 14px;
          text-align: center;
          margin: 0;
        }
      }
      .login-form {
        padding: 30px;
        border-left: 1px solid #ccc;
        .title {
          margin: 0 0 20px;
          text-align: center;
          font-size: 28px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        .help-block {
          color: #dc3545;
          font-size: 14px;
          margin-top: 5px;
          &.newpassword {
            min-height: 21px;
          }
        }
        .btn-link {
          border: none;
          background: transparent;
        }
        .w-100 {
          width: 100% !important;
        }
        .field-lbl {
          font-weight: 400;
          color: #9a9a9a;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.close-btn {
  padding: 0;
  border: none;
  font-size: 20px;
}
@media (max-width: 767px) {
  #root {
    .login-container {
      .login-card {
        max-width: 90%;
        flex-wrap: wrap;
        .info-container {
          flex: 0 0 100%;
          padding: 20px 30px;
          p {
            display: none;
          }
        }
      }
    }
  }
}
