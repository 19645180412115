.input-mask-btn {
  position: relative;
  input {
    height: 44px;
  }
  .mask-password {
    background: transparent;
    border: none;
    color: #9a9a9a;
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    width: 40px;
    padding: 0;
  }
}
