@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon-new.eot?7kolko");
  src: url("fonts/icomoon-new.eot?7kolko#iefix") format("embedded-opentype"),
    url("fonts/icomoon-new.ttf?7kolko") format("truetype"),
    url("fonts/icomoon-new.woff?7kolko") format("woff"),
    url("fonts/icomoon-new.svg?7kolko#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*AR479*/
.icon-building:before {
  content: "\e900";
}
.icon-site-wizard:before {
  content: "\e901";
}
.icon-view-permits:before {
  content: "\e902";
}
.icon-manage_residents:before {
  content: "\e903";
}
.icon-lpr-report:before {
  content: "\e904";
}
.icon-ticket-list:before {
  content: "\e905";
}
